import React from "react";
import { Box, Avatar } from "@100mslive/react-ui";

import { getAvatarUrl } from "../../utils";

export const UserAvatar = ({ name, username, css, size = 32 }) => {
  const avatarUrl = getAvatarUrl(username);

  if (avatarUrl) {
    return (
      <Box
        css={{
          width: size,
          height: size,
          borderRadius: "50%",
          overflow: "hidden",
          ...css,
        }}
      >
        <img
          style={{ height: "100%", width: "100%" }}
          alt={name}
          src={avatarUrl}
        />
      </Box>
    );
  }

  return (
    <Avatar
      name={name}
      css={{
        position: "unset",
        transform: "unset",
        mr: "$8",
        fontSize: "$sm",
        size: "$12",
        p: "$4",
      }}
    />
  );
};
