import React from "react";
import { Flex } from "@100mslive/react-ui";
import { ParticipantCount } from "./ParticipantList";
import { Logo } from "./HeaderComponents";

export const ConferencingHeader = () => {
  return (
    <Flex
      justify="between"
      align="center"
      css={{ position: "relative", height: "100%" }}
    >
      <Flex align="center" css={{ position: "absolute", left: "$10" }}>
        <Logo />
      </Flex>

      <Flex
        align="center"
        css={{
          position: "absolute",
          right: "$10",
          gap: "$4",
        }}
      >
        <ParticipantCount />
      </Flex>
    </Flex>
  );
};
