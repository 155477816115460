const host = window.location.hostname;

export let FILE_URL = "https://storage.googleapis.com/prono-files/";
export let API_URL = "";
export let APP_URL = "";
export let isLocal = false;

if (host === "localhost") {
  isLocal = true;
  API_URL = `http://${host}:6001/v1.0/`;
  APP_URL = `http://${host}:8080`;
  FILE_URL = "https://storage.googleapis.com/prono-dev-files/";
} else if (host.indexOf("dev") !== -1) {
  API_URL = "https://dev-api.brainbitesai.com/v1.0/";
  APP_URL = "https://dev.brainbitesai.com";
  FILE_URL = "https://storage.googleapis.com/prono-dev-files/";
} else {
  API_URL = "https://platform-api.brainbitesai.com/v1.0/";
  APP_URL = "https://platform.brainbitesai.com";
}
