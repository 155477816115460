import React, { useEffect, useState } from "react";

import { Tooltip, Button } from "@100mslive/react-ui";
import { SupportIcon, CloseIcon } from "@100mslive/react-icons";

const HUBSPOT_CHAT_ELEMENT_ID = "#hubspot-conversations-inline-embed-selector";

export const HubspotChatButton = ({
  label = "Support",
  tooltipTitle = "Contact support",
}) => {
  const [suportButtonVisible, setSupportButtonVisbile] = useState(true);
  const [shouldOpenToNewThread, setShouldOpenToNewThread] = useState(true);

  const hideHubspotChat = () => {
    try {
      document.querySelector(HUBSPOT_CHAT_ELEMENT_ID).style.display = "none";
    } catch (err) {
      console.error(err);
      return;
    }
    setSupportButtonVisbile(true);
  };

  const showHubspotChat = () => {
    try {
      const status = window.HubSpotConversations.widget.status() || {};
      if (!status?.loaded && status?.pending) {
        alert(
          "There was an issue loading chat. This could be due to tracking protection rules enabled in your browser."
        );
        return;
      }
      window.HubSpotConversations.widget.open();
      document.querySelector(HUBSPOT_CHAT_ELEMENT_ID).style.display = "block";
      window.HubSpotConversations.widget.open();
      if (shouldOpenToNewThread) {
        setShouldOpenToNewThread(false);
        window.HubSpotConversations.widget.refresh({ openToNewThread: true });
      }
    } catch (err) {
      console.error(err);
      return;
    }
    setSupportButtonVisbile(false);
  };

  const toggleChat = () => {
    if (!suportButtonVisible) {
      return hideHubspotChat();
    }
    showHubspotChat();
  };

  useEffect(() => {
    return () => {
      hideHubspotChat();
    };
  }, []);

  return (
    <Tooltip side="right" title={tooltipTitle}>
      <Button
        onClick={() => {
          toggleChat();
        }}
        data-testid="support_btn"
      >
        {suportButtonVisible ? (
          <>
            <SupportIcon />
            {"  "} {label}
          </>
        ) : (
          <CloseIcon />
        )}
      </Button>
    </Tooltip>
  );
};
