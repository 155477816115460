import React from "react";
import { Button, styled } from "@100mslive/react-ui";
import { isStreamingKit } from "../../common/utils";

const PreviewName = ({ name, onChange, onJoin, enableJoin }) => {
  const formSubmit = e => {
    e.preventDefault();
  };
  return (
    <Form onSubmit={formSubmit} css={{ justifyContent: "center" }}>
      <Button
        type="submit"
        disabled={!name || !enableJoin}
        onClick={onJoin}
        css={{ width: "100%" }}
      >
        {isStreamingKit() ? "Join Studio" : "Join Session"}
      </Button>
    </Form>
  );
};

const Form = styled("form", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "$4",
  mt: "$10",
  mb: "$10",
});

export default PreviewName;
